.grid-container {
  @apply flex-1 overflow-y-scroll mt-120 pr-90;

  &::-webkit-scrollbar {
    @apply w-80;
  }

  &::-webkit-scrollbar-track {
    @apply bg-slate-800 opacity-0;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-slate-500 rounded-lg;
  }

  :global {

    .grid {
      @apply grid-cols-6 gap-90 mt-50;

      .grid-timer {
        @apply flex justify-center items-center absolute top-0 w-full h-full bg-slate-900 opacity-90 rounded-lg;
        transition: opacity 300ms;

        &.hide {
          opacity: 0;
        }
      }

      .grid-item {
        @apply relative cursor-pointer;

        &:hover .layered-image {
          @apply -top-50;
        }

        &.selected .layered-image {
          @apply bg-amber-500;
        }

        &.disabled {
          @apply pointer-events-none;
        }

        .layered-image {
          @apply top-0;
          transition: all 150ms;
          border: 3px solid rgba(255, 255, 255, 0);
        }
      }
    }
  }
}