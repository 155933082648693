.action-panel {
  @apply flex justify-center items-end py-125;

  &:global(.hide) {
    @apply opacity-0 pointer-events-none;
  }

  :global {

    .slot {
      @apply h-185 border-2 bg-cover border-indigo-200 rounded-md;
      background-image: url('../assets/media/wildcard.jpg');
      aspect-ratio: 2200/2950;

      .icon {
        transition: all 300ms;
      }

      &:hover {
        .icon {
          @apply bg-indigo-500;
        }
      }
    }

    &,
    button {
      transition: all 300ms;
    }

    button {
      @apply px-120 py-50 bg-white text-indigo-700 font-bold rounded-full;

      &.hide {
        @apply opacity-0 pointer-events-none;
      }

      &.disabled {
        @apply opacity-50 pointer-events-none;
      }

      icon {
        @apply text-2xl;
        vertical-align: middle;
      }

      &:hover {
        @apply bg-indigo-400 text-white;
      }
    }
  }
}