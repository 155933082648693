@tailwind base;
@tailwind components;
@tailwind utilities;

@import '../assets/vendor/icomoon';
@import './page.scss';
@import './asuna-ratio.scss';

html,
body {
  @apply w-full h-full min-h-screen bg-slate-900 m-0 text-white;
  user-select: none;
  overflow: hidden;
}

#root {
  @apply h-full min-h-screen;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}