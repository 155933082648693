@import "icomoon-variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?8l0pj3') format('truetype'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?8l0pj3') format('woff'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?8l0pj3##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: middle;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-download:before {
  content: "\e900";
}

.icon-upload:before {
  content: "\e903";
}

.icon-arrow-left:before {
  content: "\e901";
}

.icon-arrow-right:before {
  content: "\e902";
}

.icon-swap:before {
  content: "\e904";
}

.icon-reverse-swap:before {
  content: "\e905";
}

.icon-sparkle:before {
  content: "\e906";
}

.icon-close:before {
  content: "\e907";
}