.page {
  @apply flex flex-col w-full h-full pt-120;

  .header {
    button {
      @apply inline-flex items-center justify-center py-50 bg-indigo-400 text-white font-semibold text-sm rounded-full;
      transition: background 300ms;

      &:hover {
        @apply bg-indigo-500;
      }
    }
  }

  &.hidden {
    opacity: 0 !important;
  }

  // d = depth
  // enter/exit left
  &.page-d0.page-d0-enter,
  &.page-d1.page-d1-enter,
  &.page-d2.page-d2-enter {
    @apply absolute opacity-20;
    left: -100%;
  }

  &.page-d0.page-d0-enter-active,
  &.page-d1.page-d1-enter-active,
  &.page-d2.page-d2-enter-active {
    @apply opacity-100;
    left: 0;
    transition: all 300ms ease-in-out;
  }

  &.page-d0.page-d0-exit,
  &.page-d1.page-d1-exit,
  &.page-d2.page-d2-exit {
    @apply absolute opacity-100;
    left: 0;
  }

  &.page-d0.page-d0-exit-active,
  &.page-d1.page-d1-exit-active,
  &.page-d2.page-d2-exit-active {
    @apply opacity-20;
    left: -100%;
    transition: all 300ms ease-in-out;
  }

  // enter/exit right
  &.page-d1.page-d0-enter,
  &.page-d2.page-d0-enter,
  &.page-d2.page-d1-enter,
  &.page-d3.page-d1-enter,
  &.page-d3.page-d2-enter {
    @apply absolute opacity-20;
    left: 100%;
  }

  &.page-d1.page-d0-enter-active,
  &.page-d2.page-d0-enter-active,
  &.page-d2.page-d1-enter-active,
  &.page-d3.page-d1-enter-active,
  &.page-d3.page-d2-enter-active {
    @apply opacity-100;
    left: 0;
    transition: all 300ms ease-in-out;
  }

  &.page-d1.page-d0-exit,
  &.page-d2.page-d0-exit,
  &.page-d2.page-d1-exit,
  &.page-d3.page-d1-exit,
  &.page-d3.page-d2-exit {
    @apply absolute opacity-100;
    left: 0;
  }

  &.page-d1.page-d0-exit-active,
  &.page-d2.page-d0-exit-active,
  &.page-d2.page-d1-exit-active,
  &.page-d3.page-d1-exit-active,
  &.page-d3.page-d2-exit-active {
    @apply opacity-20;
    left: 100%;
    transition: all 300ms ease-in-out;
  }
}